import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.css';
import PageHeader from '../../components/PageHeader';

// Import SVG files
import retro from '../../Assets/svg/retro.svg';
// import credit from '../../Assets/svg/credit.svg';
// import monitoring from '../../Assets/svg/monitoring.svg';
// import admin from '../../Assets/svg/admin.svg';
import developers from '../../Assets/svg/developers.svg';
import consumer from '../../Assets/svg/consumer.svg';
// import decision from '../../Assets/svg/decision.svg';

const Tile = ({
  title,
  isScorecard,
  svg, // Use svg prop instead of animationData
  route,
  isExternal,
  tileColor,
  height,
}: {
  title: string;
  isScorecard?: boolean;
  svg?: string; // Use svg prop instead of animationData
  route: string;
  isExternal?: boolean;
  tileColor?: string;
  height?: number;
}) => {
  const navigate = useNavigate();

  const handleTileClick = () => {
    if (isExternal) {
      window.open(route, "_blank");
    } else {
      navigate(route);
    }
  };

  return (
    <div
      className={`${styles.tile} ${isScorecard ? styles.scorecardTile : ''}`}
      onClick={handleTileClick}
      style={{ backgroundColor: tileColor }}
    >
      {svg && (
        <div className={styles.svgContainer} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <img src={svg} alt={title} style={{ height: height }} />
        </div>
      )}
      <div className={styles.tileTitle}>{title}</div>
    </div>
  );
};

const HomePage = () => {
  return (
    <div>
      <PageHeader />
      <div className={styles.gridWrapper}>
        <div className={styles.tileContainer}>
          <div className={styles.firstRow}>
            {/* <Tile
              title="CREDIT RISK CONSOLE"
              isScorecard
              svg={decision} // Pass SVG file instead of animationData
              route="/creditrisk-overview"
              tileColor="rgba(55, 211, 122, 0.6)"
              height={200}
            /> */}
            <Tile
              title="RETROSPECTIVE ANALYSIS"
              isScorecard
              svg={retro} // Pass SVG file instead of animationData
              route="/retro-overview"
              tileColor="rgba(55, 211, 122, 0.6)"
              height={200}
            />
              <Tile
              title="DEVELOPERS"
              svg={developers} // Pass SVG file instead of animationData
              route="https://developers.finexos.io/"
              isExternal
              tileColor="rgba(55, 211, 122, 0.6)"
              height={150}
            />
            <Tile
              title="CONSUMER TOOLS"
              svg={consumer} // Pass SVG file instead of animationData
              route="/fiola-overview"
              tileColor="rgba(55, 211, 122, 0.6)"
              height={150}
            />
            {/* <Tile
              title="CREDITWORTHINESS & AFFORDABILITY"
              svg={credit} // Pass SVG file instead of animationData
              route="/scorecard"
              tileColor="rgba(55, 211, 122, 0.6)"
              height={150}
            /> */}
          </div>
          {/* <div className={styles.secondRow}>
            <Tile
              title="MONITORING"
              svg={monitoring} // Pass SVG file instead of animationData
              route="/comingsoon"
              tileColor="rgba(55, 211, 122, 0.6)"
              height={150}
            /> */}
            {/* <Tile
              title="ADMINISTRATION"
              svg={admin} // Pass SVG file instead of animationData
              route="/comingsoon"
              tileColor="rgba(55, 211, 122, 0.6)"
              height={150}
            /> */}
          
          </div>
        </div>
      </div>
    
  );
};

export default HomePage;
