import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemText, Card, CardMedia } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    h1: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 500,
      fontSize: '65px',
      lineHeight: '110%',
    },
  
    h3: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 500,
      fontSize: '36px',
      lineHeight: '120%',
    },
    h4: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 500,
      fontSize: '25px',
      lineHeight: '140%',
    },
    h5: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '120%',
      color: '#269454',
    },
    h6: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '120%',
      color: '#269454',
    },
    body1: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '150%',
    },
    h2: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '150%',
      fontStyle: 'italic',
    },
  },
});

const RetroOverview = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container sx={{ padding: '20px', color: '#333' }}>
        <Typography variant="h3" align="center" sx={{ marginBottom: '40px', color: '#269454' }}>
          How it Works
        </Typography>
        
        <Typography variant="h4" sx={{ color: '#269454'}}>
          Retrospective Analysis
        </Typography>
        <Typography variant="body1" paragraph >
          Retrospective Analysis works on current or previous loan books to detect the overall number of defaults, either present or predicted. 
          The actual defaults within the loan book are assessed first, with our AI then applying the learnings obtained, based on the traits of 
          borrowers who actually defaulted, to the entire loan portfolio. This advanced methodology enables detection of other accounts with 
          similar behavioural patterns, to determine those borrowers who are likely to repay the loan or default.
        </Typography>
        
        <Typography variant="h2" align="center" sx={{ margin: '20px 0', color: '#269454' }}>
          Rapidly detect vulnerability in your loan book
        </Typography>
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '40px' }}>
          <Box sx={{ maxWidth: '50%', textAlign: 'left' }}>
            <Typography variant="h4" sx={{ color: '#269454'}}>
              Step 1: Share your data through our secure vault
            </Typography>
            <List sx={{ listStyleType: 'disc', pl: 2 }}>
              <ListItem sx={{ display: 'list-item' }}>
                <ListItemText primary="Data accepted in heterogeneous formats" />
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <ListItemText primary="No PII required" />
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <ListItemText primary="ISO 27001 certified" />
              </ListItem>
            </List>
            
            <Typography variant="h4" sx={{ color: '#269454'}}>
              Step 2: Loan book data assessed by FIOLA®
            </Typography>
            <List sx={{ listStyleType: 'disc', pl: 2 }}>
              <ListItem sx={{ display: 'list-item' }}>
                <ListItemText primary="Data QA process" />
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <ListItemText primary="Transited to the FIOLA® risk engine" />
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <ListItemText primary="Data screened through 300+ factors to identify risk" />
              </ListItem>
            </List>
            
            <Typography variant="h4" sx={{ color: '#269454'}} >
              Step 3: See your results
            </Typography>
            <List sx={{ listStyleType: 'disc', pl: 2 }}>
              <ListItem sx={{ display: 'list-item' }}>
                <ListItemText primary="Vulnerability analysis" />
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <ListItemText primary="Capital at risk" />
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <ListItemText primary="Probability of default" />
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <ListItemText primary="Summary & recommendations" />
              </ListItem>
            </List>
          </Box>
          
          <Box sx={{ maxWidth: '45%', textAlign: 'center' }}>
            <Typography variant="h4" sx={{ marginBottom: '60px', color: '#269454' }}>
              Watch our How-To Video
            </Typography>
            <Card>
              <CardMedia
                component="video"
                controls
                src="https://www.finexos.io/wp-content/uploads/2024/05/FinexosLBVA-VIDEO2024.mp4"
                sx={{ width: '100%' }}
              />
            </Card>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default RetroOverview;
