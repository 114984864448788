import React, { useState, useEffect } from 'react';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';
import { AiOutlineMenu } from 'react-icons/ai';
import styles from './index.module.css';
import { routes } from '../../constants/routes';

export default function SideMenu(): JSX.Element {
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsCollapsed(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSidebar = () => setIsCollapsed(!isCollapsed);

  return (
    <>
      <AiOutlineMenu className={styles.hamburgerMenu} onClick={toggleSidebar} />
      <Sidebar
        collapsed={isCollapsed}
        collapsedWidth={'0px'}
        width={'305px'}
        className={styles.container}
        backgroundColor='#2d302e'
      >
        <Menu
          menuItemStyles={{
            button: {
              backgroundColor: '#2d302e',
              '&:hover': {
                backgroundColor: '#2d302e',
                color: '#36d378',
              },
              color: 'white',
            },
          }}
        >
         {/* <MenuItem
  component={
    <Link 
      to={routes.creditrisk} 
      className={location.pathname === routes.creditrisk ? `${styles.active} ${styles.menuItem}` : styles.menuItem} 
    />
  }
  className={styles.header}
  style={{
    color: location.pathname === routes.creditrisk ? '#36d378' : '#FFFF',
    textDecoration: 'none',
    position: 'relative', 
  }}
>
  <span style={{
    borderBottom: '2px solid currentColor', 
    paddingBottom: '1px', 
  }}>
    Credit Risk Console
  </span>
</MenuItem> */}

{/* <MenuItem
  component={<Link to={routes.scorecardManagement} className={location.pathname === routes.scorecardManagement ? `${styles.active} ${styles.menuItem}` : styles.menuItem} />}
  style={{
    color: location.pathname === routes.scorecardManagement ? '#36d378' : '#FFFF',
  }}
>
  Risk Configuration Tool
</MenuItem>

<MenuItem
  component={<Link to={routes.weightings} className={location.pathname === routes.weightings ? `${styles.active} ${styles.menuItem}` : styles.menuItem} />}
  style={{
    color: location.pathname === routes.weightings ? '#36d378' : '#FFFF',
  }}
>
  Current Credit Risk Model Weightings
</MenuItem>

<MenuItem
  component={<Link to={routes.scorecard} className={location.pathname === routes.scorecard ? `${styles.active} ${styles.menuItem}` : styles.menuItem} />}
  style={{
    color: location.pathname === routes.scorecard ? '#36d378' : '#FFFF',
  }}
>
  Retraining Console
</MenuItem>

<MenuItem
  component={<Link to={routes.modelGovernance} className={location.pathname === routes.modelGovernance ? `${styles.active} ${styles.menuItem}` : styles.menuItem} />}
  style={{
    color: location.pathname === routes.modelGovernance ? '#36d378' : '#FFFF',
  }}
>
  Governance Console
</MenuItem> */}

<MenuItem
  component={
    <Link 
      to={routes.retrooverview} 
      className={location.pathname === routes.retrooverview ? `${styles.active} ${styles.menuItem}` : styles.menuItem}
      style={{
        color: location.pathname === routes.retrooverview ? '#36d378' : '#FFFF',
        textDecoration: 'none',
        position: 'relative',
      }}
    />
  }
  className={styles.header}
>
  <span style={{
    borderBottom: '2px solid currentColor',
    paddingBottom: '1px',
  }}>
    Retrospective Analysis
  </span>
</MenuItem>

<MenuItem
  component={<Link to={routes.lbvaexample} className={location.pathname === routes.lbvaexample ? `${styles.active} ${styles.menuItem}` : styles.menuItem} />}
  style={{
    color: location.pathname === routes.lbvaexample ? '#36d378' : '#FFFF',
  }}
>
  Examples
</MenuItem>

<MenuItem
  component={<Link to={routes.vaultguide} className={location.pathname === routes.vaultguide ? `${styles.active} ${styles.menuItem}` : styles.menuItem} />}
  style={{
    color: location.pathname === routes.vaultguide ? '#36d378' : '#FFFF',
  }}
>
  Vault Guide
</MenuItem>

{/* <MenuItem
  component={
    <Link 
      to={routes.creditoverview} 
      className={location.pathname === routes.creditoverview ? `${styles.active} ${styles.menuItem}` : styles.menuItem}
      style={{
        color: location.pathname === routes.creditoverview ? '#36d378' : '#FFFF',
        textDecoration: 'none',
        position: 'relative',
      }}
    />
  }
  className={styles.header}
>
  <span style={{
    borderBottom: '2px solid currentColor',
    paddingBottom: '1px',
  }}>
    Creditworthiness and Affordability
  </span>
</MenuItem>

<MenuItem
  component={<Link to={routes.underconstruction} className={location.pathname === routes.underconstruction ? `${styles.active} ${styles.menuItem}` : styles.menuItem} />}
  style={{
    color: location.pathname === routes.home ? '#36d378' : '#FFFF',
  }}
>
  Affordability Engine
</MenuItem>

<MenuItem
  component={<Link to={routes.underconstruction} className={location.pathname === routes.underconstruction ? `${styles.active} ${styles.menuItem}` : styles.menuItem} />}
  style={{
    color: location.pathname === routes.home ? '#36d378' : '#FFFF',
  }}
>
  Salary Verification Engine
</MenuItem>

<MenuItem
  component={<Link to={routes.underconstruction} className={location.pathname === routes.underconstruction ? `${styles.active} ${styles.menuItem}` : styles.menuItem} />}
  style={{
    color: location.pathname === routes.home ? '#36d378' : '#FFFF',
  }}
>
  Loan Book Predictions
</MenuItem>

<MenuItem
  component={<Link to={routes.underconstruction} className={location.pathname === routes.underconstruction ? `${styles.active} ${styles.menuItem}` : styles.menuItem} />}
  style={{
    color: location.pathname === routes.home ? '#36d378' : '#FFFF',
  }}
>
  Metrics
</MenuItem> */}

<MenuItem
  component={
    <Link 
      to={routes.fiolaoverview} 
      className={location.pathname === routes.fiolaoverview ? `${styles.active} ${styles.menuItem}` : styles.menuItem}
      style={{
        color: location.pathname === routes.fiolaoverview ? '#36d378' : '#FFFF',
        textDecoration: 'none',
        position: 'relative',
      }}
    />
  }
  className={styles.header}
>
  <span style={{
    borderBottom: '2px solid currentColor',
    paddingBottom: '1px',
  }}>
    Consumer Tools
  </span>
</MenuItem>

<MenuItem
  component="a" href="https://demo.exzt.finexos.io/login" target="_blank" rel="noopener noreferrer"
>
  Open Banking Onboarding
</MenuItem>

<MenuItem
  component={<Link to={routes.fiolapp} className={location.pathname === routes.fiolapp ? `${styles.active} ${styles.menuItem}` : styles.menuItem} />}
  style={{
    color: location.pathname === routes.fiolapp ? '#36d378' : '#FFFF',
  }}
>
  Fiola App
</MenuItem>

<MenuItem
  component={<Link to={routes.avalnce} className={location.pathname === routes.avalnce ? `${styles.active} ${styles.menuItem}` : styles.menuItem} />}
  style={{
    color: location.pathname === routes.avalnce ? '#36d378' : '#FFFF',
  }}
>
  Debt Restructuring
</MenuItem>

{/* <MenuItem
  component={
    <Link 
      to={routes.creditoverview} 
      className={location.pathname === routes.creditoverview ? `${styles.active} ${styles.menuItem}` : styles.menuItem}
      style={{
        color: location.pathname === routes.home ? '#36d378' : '#FFFF',
        textDecoration: 'none',
        position: 'relative',
      }}
    />
  }
  className={styles.header}
>
  <span style={{
    borderBottom: '2px solid currentColor',
    paddingBottom: '1px',
  }}>
    Monitoring
  </span>
</MenuItem>

<MenuItem
  component={
    <Link 
      to={routes.creditoverview} 
      className={location.pathname === routes.creditoverview ? `${styles.active} ${styles.menuItem}` : styles.menuItem}
      style={{
        color: location.pathname === routes.home ? '#36d378' : '#FFFF',
        textDecoration: 'none',
        position: 'relative',
      }}
    />
  }
  className={styles.header}
>
  <span style={{
    borderBottom: '2px solid currentColor',
    paddingBottom: '1px',
  }}>
    Administration
  </span>
</MenuItem> */}

<MenuItem
  component={
    <Link 
      to={routes.resources} 
      className={location.pathname === routes.resources ? `${styles.active} ${styles.menuItem}` : styles.menuItem}
      style={{
        color: location.pathname === routes.resources ? '#36d378' : '#FFFF',
        textDecoration: 'none',
        position: 'relative',
      }}
    />
  }
  className={styles.header}
>
  <span style={{
    borderBottom: '2px solid currentColor',
    paddingBottom: '1px',
  }}>
    Developers
  </span>
</MenuItem>

<MenuItem
  component="a" href="https://finexos.io" target="_blank" rel="noopener noreferrer"
>
  Finexos Website
</MenuItem>

          <MenuItem></MenuItem>
          <MenuItem></MenuItem>
          <MenuItem></MenuItem>
          <MenuItem></MenuItem>
          <MenuItem></MenuItem>
          <MenuItem></MenuItem>
          <MenuItem></MenuItem>
          <MenuItem></MenuItem>
          <MenuItem></MenuItem>
          <MenuItem></MenuItem>
          <MenuItem></MenuItem>
          <MenuItem></MenuItem>
        
        </Menu>
      </Sidebar>
    </>
  );
}
