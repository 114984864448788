import { Outlet } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import styles from './AuthLayout.module.css';



export default function AuthLayout(): JSX.Element {
  return (
    <div>
      <PageHeader />
      <div className={styles.container}>
      
        <div className={styles.main}>
          <Outlet />
          
        </div>
      </div>

    </div>
    
  );
}
