import React from 'react';
import Lottie from 'react-lottie';
import underConstructionAnimation from '../../Assets/lottie/construction.json'; 

const UnderConstruction = () => {
  // Lottie options
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: underConstructionAnimation, 
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft:'20%', marginTop: '100px' }}>
     
      <h1 style={{ textAlign: 'center' }}>Under Construction</h1>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
        <Lottie 
          options={defaultOptions}
          height={400}  
          width={400}
        />
      </div>
    </div>
  );
};

export default UnderConstruction;
