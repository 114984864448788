import React from 'react';
import { Container, Typography, Box, CardMedia } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';


import firstImage from '../../Assets/images/first.png';
import secondImage from '../../Assets/images/second.png';
import thirdImage from '../../Assets/images/third.png';
import first from '../../Assets/images/1first.png';
import second from '../../Assets/images/2second.png';

const images = [firstImage, secondImage, thirdImage];
const secondImages = [first, second];

const Arrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block',  }}
      onClick={onClick}
    />
  );
};
const theme = createTheme({
  typography: {
    h1: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 500,
      fontSize: '65px',
      lineHeight: '110%',
    },
  
    h3: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 500,
      fontSize: '36px',
      lineHeight: '120%',
    },
    h4: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 500,
      fontSize: '25px',
      lineHeight: '140%',
    },
    h5: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '120%',
      color: '#269454',
    },
    h6: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '120%',
      color: '#269454',
    },
    body1: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '150%',
    },
    h2: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '150%',
      fontStyle: 'italic',
    },
  },
});


const LbvaExamples = () => {
  const settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <Arrow />,
    prevArrow: <Arrow />,
    appendDots: (dots: any) => (
      <div>
        <ul style={{ margin: '0px' }}> {dots} </ul>
      </div>
    ),
    customPaging: (i: number) => (
      <div
        style={{
          width: '30px',
          color: 'black',
         
        }}
      >
        {i + 1}
      </div>
    ),
  };

  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <Arrow />,
    prevArrow: <Arrow />,
    appendDots: (dots: any) => (
      <div>
        <ul style={{ margin: '0px' }}> {dots} </ul>
      </div>
    ),
    customPaging: (i: number) => (
      <div
        style={{
          width: '30px',
          color: 'black',
         
        }}
      >
        {i + 1}
      </div>
    ),
  };

  return (
    <ThemeProvider theme={theme}>
    <Container sx={{ padding: '20px', color: '#269454' }}>
      <Typography variant="h3" align="center" sx={{ marginBottom: '20px', color: '#269454', fontWeight: '500' }}>
        LBVA Examples
      </Typography>
      <Typography variant="body1" align="center" sx={{ marginBottom: '20px', color: 'black' }}>
        Below are some examples of our Loan Book Vulnerability Analysis (LBVA) in action.
      </Typography>
      <Box sx={{ textAlign: 'center' }}>
       
          <Slider {...settings1}>
            {images.map((image, index) => (
              <CardMedia
                key={`first-slider-${index}`}
                component="img"
                image={image}
                alt={`LBVA Example ${index + 1}`}
                sx={{ maxWidth: '80%', height: 'auto' }}
              />
            ))}
          </Slider>
          <Typography variant="body1" sx={{ marginTop: '40px', color: 'black' }}>
            Here are some further examples based off open banking data.
          </Typography>
          <Slider {...settings2}>
            {secondImages.map((image, index) => (
              <CardMedia
                key={`second-slider-${index}`}
                component="img"
                image={image}
                alt={`LBVA Example ${index + 1}`}
                sx={{ maxWidth: '80%', height: 'auto' }}
              />
            ))}
          </Slider>
     
      </Box>
    </Container>
    </ThemeProvider>
  );
};

export default LbvaExamples;
