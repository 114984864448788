const Track = ({ track: { id, source, target }, getTrackProps }: { track: { id: string, source: any, target: any }, getTrackProps: any }) => (
    <div key={id} {...getTrackProps()}>
      <div
        style={{
          position: 'absolute',
          height: `calc(${source.percent}%)`,
          zIndex: 1,
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          width: '50px',
          top: 0,
        }}
      />
      <div
        style={{
          position: 'absolute',
          height: `calc(${100 - target.percent}%)`,
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          width: '50px',
          bottom: 0,
        }}
      />
    </div>
  );

export default Track;