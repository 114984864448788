import React, { useState } from 'react';
import { Table, Pagination } from 'react-bootstrap';

const ApplicantsTable = ({ data, itemsPerPage = 10 }: { data: any[], itemsPerPage?: number }) => {

    const formatPD = (pd: number) => {
      return (parseFloat(pd.toFixed(2)) * 100).toFixed(2);
    }
  
    const formatAPR = (apr: number) => {
      return (apr * 100)?.toFixed(2) + '%';
    }

    const formatter = new Intl.NumberFormat('en-GB', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    
    const [currentPage, setCurrentPage] = useState(1);
  
    const lastItemIndex = currentPage * itemsPerPage;
    const firstItemIndex = lastItemIndex - itemsPerPage;
    const currentItems = data.slice(firstItemIndex, lastItemIndex);
  
    const totalPages = Math.ceil(data.length / itemsPerPage);
  
    const handleChangePage = (pageNumber: number) => {
      setCurrentPage(pageNumber);
    };
  
    return (
      <>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>User ID</th>
              <th>Amount</th>
              <th>Terms</th>
              <th>APR</th>
              <th>FCS</th>
              <th>PD</th>
              <th>Band</th>
              <th>Decision</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((loan, index) => (
              <tr key={index}>
                <td>{loan?.userId}</td>
                <td>£{formatter.format(loan?.loanAmount)}</td>
                <td>{loan?.loanTerm}</td>
                <td>{formatAPR(loan?.apr)}</td>
                <td>{loan?.fcs?.[0]}</td>
                <td>{formatPD(loan?.fiolaPD) + '%'}</td>
                <td>{loan?.band}</td>
                <td>{loan?.decision}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination className="justify-content-center">
          <Pagination.First onClick={() => handleChangePage(1)} disabled={currentPage === 1}>{'<<'}</Pagination.First>
          <Pagination.Prev onClick={() => handleChangePage(currentPage - 1)} disabled={currentPage === 1}>{'<'}</Pagination.Prev>
  
          {currentPage > 3 && <Pagination.Ellipsis disabled />}
  
          {
            (() => {
              let startPage = Math.max(currentPage - 2, 1);
              let endPage = Math.min(startPage + 4, totalPages);
  
              if (totalPages - currentPage < 2) {
                startPage = Math.max(totalPages - 4, 1);
              }
  
              return Array.from({ length: (endPage - startPage) + 1 }, (_, index) => startPage + index)
                .map(number => (
                  <Pagination.Item key={number} active={number === currentPage} onClick={() => handleChangePage(number)}>
                    {number}
                  </Pagination.Item>
                ));
            })()
          }
  
          {currentPage < totalPages - 2 && <Pagination.Ellipsis disabled />}
  
          <Pagination.Next onClick={() => handleChangePage(currentPage + 1)} disabled={currentPage === totalPages}>{'>'}</Pagination.Next>
          <Pagination.Last onClick={() => handleChangePage(totalPages)} disabled={currentPage === totalPages}>{'>>'}</Pagination.Last>
        </Pagination>
      </>
    );
  };

  export default ApplicantsTable;