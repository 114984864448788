const Tick = ({ tick: { id, percent }, rangeValues, getLabel }: { tick: { id: string, percent: number }, rangeValues: any, getLabel: (index: number) => string }) => {
    const isBand = rangeValues?.includes(percent);
    const index = rangeValues?.indexOf(100 - percent);
    return (
      <div key={id} style={{
        position: 'absolute',
        top: `${percent}%`,
        width: '50px',
        height: '2px',
        background: !isBand ? 'linear-gradient(to right, transparent 0%, transparent 81%, black 81.1%, black 100%)' : 'black',
      }}>
        {
          isBand && index !== -1 &&
          <div style={{
            whiteSpace: 'nowrap',
            textAlign: 'right',
            position: 'absolute',
            right: '70px',
            top: `calc(${percent}% - 12px)`
          }}>
            {getLabel(index)}
          </div>
        }
        <div style={{
          whiteSpace: 'nowrap',
          textAlign: 'left',
          left: '70px',
          position: 'absolute',
          top: `calc(${percent}% - 12px)`
        }}>
          {percent === 0 ? '%' : Math.floor(100 - percent)}
        </div>
      </div>
    )
  };

  export default Tick;