import React, { useState, useEffect, FormEvent } from 'react';
import { Form, Button, Tabs, Tab } from 'react-bootstrap';
import RulerSlider from './components/RulerSlider/RulerSlider';
import ApplicantsTable from './components/ApplicantsTable/ApplicantsTable';
import styles from './index.module.css';
import { UserInfoContext } from '../../context/user-info';

const DecisionOverview = ({ data, getRangeValues, getRangeLabels }: { data: any[], getRangeValues: any, getRangeLabels: any }) => {
  const calculateBand = (score: number, rangeValues: number[], rangeLabels: string[]) => {
    for (let i = 0; i < rangeValues.length; i++) {
      if (score <= rangeValues[i]) {
        return rangeLabels[i];
      }
    }
    return '-';
  }
  const formatter = new Intl.NumberFormat('en-GB', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const approvedData = data.filter((row) => row?.decision === 'Approved');
  const approvedCount = approvedData.length;
  const totalLoanAmount = Math.round(approvedData.reduce((acc, row) => acc + row.loanAmount, 0));
  const averageAPR = approvedData.reduce((acc, row) => acc + (row.apr * 100), 0) / approvedData.length;
  const maximumInterestProfit = Math.round(approvedData.reduce(
    (acc, row) => acc + (((row.apr / 12) * row.loanAmount) * row.loanTerm),
    0
  ));
  const expectedInterestProfit = Math.round(
    approvedData.filter((row) => row?.fiolaPrediction === 1).reduce(
      (acc, row) => acc + (((row.apr / 12) * row.loanAmount) * row.loanTerm),
      0
    ));
  const averageFiolaPD = approvedData.reduce(
    (acc, row) => acc + (parseFloat(row?.fiolaPD?.toFixed(2)) * 100), 0
  ) / approvedData.length;
  const predictedDefaults = approvedData.filter((row) => row?.fiolaPrediction === 0).length;
  const averageBand = calculateBand(averageFiolaPD, getRangeValues(), getRangeLabels());
  return (
    <>
      <div className={styles.scorecardManagementCardColumn}>
        <p>No. of loans: {approvedCount} of {data.length}</p>
        <p>Maximum interest profit: £{formatter.format(maximumInterestProfit)}</p>
        <p>Capital needed: £{formatter.format(totalLoanAmount)}</p>
        <p>Avg Risk Band: {averageBand}</p>
      </div>
      <div className={styles.scorecardManagementCardColumn}>
        <p>Expected interest profit: £{formatter.format(expectedInterestProfit)}</p>
        <p>Avg interest rate: {(averageAPR && !isNaN(averageAPR) ? averageAPR?.toFixed(2) : 0) + '%'}</p>
        <p>Avg PD: {(averageFiolaPD && !isNaN(averageFiolaPD) ? averageFiolaPD?.toFixed(2) : 0)  + '%'}</p>
        <p>Predicted defaults: {predictedDefaults}</p>
      </div>
    </>
  )
}

export default function ScorecardManagement(): JSX.Element {
  const [sliderValues, setSliderValues] = useState([0, 75]);
  const [tempLoanData, setTempLoanData] = useState<any[]>([]);
  const {
    getRangeValues,
    getRangeLabels,
    getInterestRates,
    setRangeValues,
    setRangeLabels,
    getThreshold,
    setThreshold,
    getLoanData,
    setLoanData
  } = React.useContext(UserInfoContext);

  useEffect(() => {
    setSliderValues(getThreshold());
    setTempLoanData(getLoanData());
  }, [getThreshold, getLoanData]);

  useEffect(() => {
    setTempLoanData(currentLoanData => {
      const calculateDecision = (score: number) => {
        const pd = parseFloat(score.toFixed(2)) * 100;
        sliderValues.sort((a, b) => a - b);
        if (pd >= sliderValues[0] && pd <= sliderValues[1]) {
          return 'Approved';
        }
        return 'Declined';
      };
      return currentLoanData.map((row: any) => {
        row.decision = calculateDecision(row?.["fiolaPD"]);
        return row;
      });
    });
  }, [sliderValues, setTempLoanData]);


  const getRangeFormatedLabel = (index: number) => {
    const rangeValues = getRangeValues();
    if (index === 0) {
      return rangeValues[index];
    }
    return rangeValues[index - 1] + " - " + rangeValues[index];
  }

  const setSliderValue = (index: number, value: number) => {
    const newValues = [...sliderValues];
    newValues[index] = value;
    setSliderValues(newValues);
  }

  const getTicks = () => {
    const rangeValues = getRangeValues();
    const stepValues = Array.from({ length: (100 / 5) + 1 }, (_, i) => i * 5);
    const uniqueValues = Array.from(new Set([...rangeValues, ...stepValues])).sort((a, b) => a - b);
    return uniqueValues;
  }

  const getLabel = (index: number) => {
    const rangeLabels = getRangeLabels();

    return rangeLabels[index];
  }
  const adjustInterest = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  }

  const removeHandle = (index: number) => {
    const rangeValues = getRangeValues();
    const rangeLabels = getRangeLabels();
    setRangeValues(rangeValues.filter((_, i) => i !== index));
    setRangeLabels(rangeLabels.filter((_, i) => i !== index));
  };

  const saveThreshold = () => {
    setThreshold(sliderValues);
    setLoanData(tempLoanData);
  }

  const shouldConfirm = () => {
    const sortedArray1 = [...sliderValues].sort();
    const sortedArray2 = [...getThreshold()].sort();

    if (sortedArray1.length !== sortedArray2.length) {
      return true;
    }

    for (let i = 0; i < sortedArray1.length; i++) {
      if (sortedArray1[i] !== sortedArray2[i]) {
        return true;
      }
    }
    return false;
  }

  return (
    <div className={styles.scorecardManagementContainer}>
      <h1 className={styles.scorecardManagementHeader}>Risk Configuration Tool</h1>
      <Tabs defaultActiveKey="second" id="uncontrolled-tab-example">
        <Tab eventKey="second" title="Risk Config">
          <div className={styles.scorecardManagementRiskRow}>
            <div className={styles.scorecardManagementColumnHalf}>
              <p>
                The Fiola Risk Configuration Tool is designed to give control over the lender's risk appetite. Easily set your preferred risk thresholds and make informed lending decisions. The tool can be used as a standalone decision tool, or as an integrated feature into an existing decision engine.
              </p>
              <h5>How It Works</h5>
              <p>
                <strong>Slider Scale:</strong> The tool features a slider scale ranging from 0 to 100. This scale represents the probability of default (PD) for each borrower. A higher number indicates a higher risk of default.
              </p>
              <p>
                <strong>Customisable Risk Bands:</strong> Adjust two distinct risk bands on the slider to fit your risk appetite:

                <p> <strong>Low-Risk Band:</strong> Set the lower limit of PD</p>
                <p><strong>High-Risk Band:</strong> Set the upper limit of PD</p>

                Loans within these bands can be automatically approved, subject to the lender’s business process.
              </p>
              <div className={styles.scorecardManagementCard}>
                <h2 className={styles.scorecardManagementCardTitle}>Decision Overview</h2>
                <p>
                  As you adjust your risk bands, the Fiola Risk Configuration Tool provides real-time feedback on your predicted loan book performance. You'll see key metrics like:
                </p>
                <div className={styles.scorecardManagementColumnsContainer}>
                  <DecisionOverview
                    data={tempLoanData}
                    getRangeLabels={getRangeLabels}
                    getRangeValues={getRangeValues}
                  />
                </div>
              </div>
              {
                shouldConfirm() &&
                <Button
                  className={styles.scorecardManagementButton}
                  onClick={saveThreshold}
                >
                  Confirm
                </Button>
              }
            </div>
            <div className={styles.scorecardManagementColumn}>
              <h4 className={styles.scorecardManagementSliderTitle}>Risk Band</h4>
              <RulerSlider
                sliderValues={sliderValues}
                setSliderValues={setSliderValues}
                removeHandle={removeHandle}
                setSliderValue={setSliderValue}
                getTicks={getTicks}
                getRangeValues={getRangeValues}
                getLabel={getLabel}
              />
            </div>
          </div>
          <div className={styles.scorecardManagementRow}>
            <ApplicantsTable data={tempLoanData} itemsPerPage={25} />
          </div>
        </Tab>

        <Tab eventKey="third" title="Interest Config">
          <div className={styles.scorecardManagementInterestRow}>
            <div className={styles.scorecardManagementColumnHalf}>
              <p>
                The Fiola Interest Configuration Tool enables you to fine-tune your interest rates and maximise the profitability of your loan portfolio. By tailoring interest rates to different risk profiles, you can balance risk and reward.
              </p>
              <h5>How It Works</h5>
              <p>
                <strong>Dynamic Interest Rate Setting:</strong> The tool seamlessly integrates with your risk configuration settings. As you adjust your risk bands, the interest configuration tool dynamically suggests corresponding interest rates for each band.
              </p>
              <p>
                <strong>Customisable Interest Rates:</strong> You have the flexibility to manually adjust the suggested interest rates within each risk band.
              </p>

              <div className={styles.scorecardManagementCard}>
                <h2 className={styles.scorecardManagementCardTitle}>Decision Overview</h2>
                <p>
                  This tool provides a clear visual representation of how interest rates vary across different risk levels. This helps you understand the impact of your interest rate choices on the loan book.
                </p>
                <div className={styles.scorecardManagementColumnsContainer}>
                  <DecisionOverview
                    data={tempLoanData}
                    getRangeLabels={getRangeLabels}
                    getRangeValues={getRangeValues}
                  />
                </div>
              </div>
              <Button className={styles.scorecardManagementButton}> Confirm</Button>
            </div>
            <div className={styles.scorecardManagementColumn}>
              <Form className={styles.scorecardManagementInput} onSubmit={adjustInterest}>
                {getRangeLabels().map((label, index) => (
                  <Form.Group key={index} className="form-group">
                    <Form.Label>{label + " risk (" + getRangeFormatedLabel(Number(index)) + ")"} </Form.Label>
                    <Form.Control
                      type="number"
                      min={0}
                      max={100}
                      defaultValue={getInterestRates()?.[index]}
                    />
                  </Form.Group>
                ))}
              </Form>
            </div>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}