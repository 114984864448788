import React from 'react';
import { UserInfoContext } from '../../context/user-info';
import ApplicantsTable from '../ScorecardManagement/components/ApplicantsTable/ApplicantsTable';
import styles from './index.module.css';

export default function Applicants(): JSX.Element {
  const {
    getLoanData
  } = React.useContext(UserInfoContext);

  return (
    <div className={styles.applicantsContainer}>
      <h1 className={styles.applicantsHeader}>Applicants</h1>

      <div className={styles.applicantsRow}>
        <ApplicantsTable data={getLoanData()} itemsPerPage={25} />
      </div>
    </div>
  );
}
