import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemText } from '@mui/material';

const CreditworthinessOverview = () => {
  return (
    <Container sx={{ padding: '20px', color: '#333' }}>
      <Typography variant="h4" align="center" sx={{ marginBottom: '40px', color: '#269454', fontWeight: '500' }}>
        Creditworthiness and Affordability
      </Typography>
      
      <Typography variant="h5" sx={{ color: '#269454', fontWeight: '500' }}>
        Credit Risk
      </Typography>
      <Typography paragraph>
        Credit risk involves assessing the likelihood that a borrower will default on their debt obligations.
      </Typography>
      
      <Typography variant="h5" sx={{ color: '#269454', fontWeight: '500' }}>
        Affordability Risk
      </Typography>
      <Typography paragraph>
        Affordability risk evaluates a borrower’s ability to repay a loan based on their income and expenditure.
      </Typography>
      
      <Typography variant="h5" sx={{ color: '#269454', fontWeight: '500' }}>
        How do lenders assess risk now?
      </Typography>
      <Box sx={{ maxWidth: '50%', textAlign: 'left' }}>
        <List sx={{ listStyleType: 'disc', pl: 2 }}>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary="Borrower information (Application & internal data)" />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary="Internal score cards (CRA data)" />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary="Peps, Sanctions etc (CRA data)" />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary="Income validation (CATO data)" />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary="Debt to Income ratios (Debt on CRA report)" />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary="Income & Expenditure assessment (basic based on validated income & ONS data and/or open banking data)" />
          </ListItem>
        </List>
      </Box>
      
      <Typography variant="h5" sx={{ color: '#269454', fontWeight: '500' }}>
        How is the Finexos model unique?
      </Typography>
      <Typography paragraph>
        What distinguishes Finexos is our pioneering methodology in data science and data engineering that leverages cutting-edge AI and ML to improve borrower risk profiling and lending decisions.
      </Typography>
      <Typography paragraph>
        Finexos goes beyond existing affordability checks with the addition of borrower financial capability factors “FCS”
      </Typography>
      <Typography paragraph>
        FCS enables a deeper understanding of an individual borrower’s financial capability (spending patterns, cash flow) and also borrower behaviour (how the borrower manages their money, prioritise creditor repayments over social events etc).
      </Typography>
      <Typography paragraph>
        The FCS metric provides a unique value for each borrower depending on their financial capability and borrower behaviour.
      </Typography>
      
      <Typography variant="h5" sx={{ color: '#269454', fontWeight: '500' }}>
        How is the Finexos model better?
      </Typography>
      <Typography paragraph>
        Unlike conventional credit risk solutions, Finexos integrates sophisticated AI and ML to provide a more accurate assessment of credit risk (use cases) 50% defaults detected at point of creditworthiness assessment)
      </Typography>
      <Typography paragraph>
        Our unique approach provides a more holistic view of a borrower's financial health and attitude to credit. This enables lenders to make significantly more accurate, inclusive and better informed lending decisions.
      </Typography>
      <Typography paragraph>
        The solution does not penalise borrowers with thin or no credit files, part-time workers or self-employed workers (“the underserved”) whilst ensuring robust risk control. It is therefore fairer, enabling lenders to lend to more customers within risk tolerance.
      </Typography>
    </Container>
  );
};

export default CreditworthinessOverview;
