import React from 'react';
import { Container, Typography, Box, Card, CardMedia } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import avalanche1 from '../../Assets/images/avalanche1.png';
import avalanche2 from '../../Assets/images/avalanche2.png';
import avalanche3 from '../../Assets/images/avalanche3.png';
import avalanche4 from '../../Assets/images/avalanche4.png';
import avalanche5 from '../../Assets/images/avalanche5.png';

const theme = createTheme({
  typography: {
    h1: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 500,
      fontSize: '65px',
      lineHeight: '110%',
    },
    h3: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 500,
      fontSize: '36px',
      lineHeight: '120%',
    },
    h4: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 500,
      fontSize: '25px',
      lineHeight: '140%',
    },
    h5: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '120%',
      color: '#269454',
    },
    h6: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '120%',
      color: '#269454',
    },
    body1: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '150%',
    },
    h2: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '150%',
      fontStyle: 'italic',
    },
  },
});

const Avalanche = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container sx={{ padding: '20px', color: '#333' }}>
        <Typography variant="h3" align="center" sx={{ marginBottom: '40px', color: '#269454' }}>
          Debt Restructuring (Avalanche)
        </Typography>
        
        <Typography variant="h4" sx={{ color: '#269454' }}>
          Getting Started
        </Typography>
        <Typography variant="body1" paragraph>
          This is our Avalanche feature. It uses Fiola's machine learning and AI-driven algorithm to calculate the cheapest and fastest way to pay your credit card balances while paying the same amount you usually do.
        </Typography>
        
        <Typography variant="h4" sx={{ color: '#269454' }}>
          Initial Setup
        </Typography>
        <Typography variant="body1" paragraph>
          Once registered and you navigate to the Avalanche page, you will be shown a screen similar to this. Every credit card you have will be displayed along with their remaining balance, average repayment, time left to clear, and cost of credit.
        </Typography>
        <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
          <Card sx={{ maxWidth: '30%', margin: '0 auto' }}>
            <CardMedia
              component="img"
              image={avalanche1}
              alt="Homepage Overview"
            />
          </Card>
        </Box>
        
        <Typography variant="h4" sx={{ color: '#269454' }}>
          View Card Repayment Values
        </Typography>
        <Typography variant="body1" paragraph>
          You can choose to select an individual card and see their current repayment values too along with a graph to give you a visual representation.
        </Typography>
        <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
          <Card sx={{ maxWidth: '30%', margin: '0 auto' }}>
            <CardMedia
              component="img"
              image={avalanche2}
              alt="Data Type Selection"
            />
          </Card>
        </Box>
        
        <Typography variant="h4" sx={{ color: '#269454' }}>
          Calculate Savings
        </Typography>
        <Typography variant="body1" paragraph>
          The magic happens when you press calculate. Fiola calculates the most efficient method in repaying your balances while maintaining the same average repayment value. As you can see, in total you're saving a massive £9339.11 and paying off your balance 3 months faster just by using Fiola.
        </Typography>
        <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
          <Card sx={{ maxWidth: '30%', margin: '0 auto' }}>
            <CardMedia
              component="img"
              image={avalanche3}
              alt="Upload Menu"
            />
          </Card>
        </Box>
        
        <Typography variant="h4" sx={{ color: '#269454' }}>
          Updated Graph
        </Typography>
        <Typography variant="body1" paragraph>
          You can see the graph updates as well, showing you visually how much time you can save.
        </Typography>
        <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
          <Card sx={{ maxWidth: '30%', margin: '0 auto' }}>
            <CardMedia
              component="img"
              image={avalanche4}
              alt="Empty Upload Menu"
            />
          </Card>
        </Box>
        
        <Typography variant="h4" sx={{ color: '#269454' }}>
          Individual Card Savings
        </Typography>
        <Typography variant="body1" paragraph>
          You can again tap on a specific card and see how much you're saving with each account. As you can see here, with this specific account you're saving £2715.29.
        </Typography>
        <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
          <Card sx={{ maxWidth: '30%', margin: '0 auto' }}>
            <CardMedia
              component="img"
              image={avalanche5}
              alt="Data Privacy Confirmation"
            />
          </Card>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Avalanche;
