import React from 'react';
import { Container, Typography, Box, Card, CardMedia } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    h1: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 500,
      fontSize: '65px',
      lineHeight: '110%',
    },
    h2: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 500,
      fontSize: '50px',
      lineHeight: '110%',
    },
    h3: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 500,
      fontSize: '36px',
      lineHeight: '120%',
    },
    h4: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 500,
      fontSize: '25px',
      lineHeight: '140%',
    },
    h5: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '120%',
      color: '#269454',
    },
    h6: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '120%',
      color: '#269454',
    },
    body1: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '150%',
    },
  },
});

const VaultGuide = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container sx={{ padding: '20px', color: '#333' }}>
        <Typography variant="h3" align="center" sx={{ marginBottom: '40px', color: '#269454' }}>
          Vault Guide
        </Typography>
        <Typography variant="body1" paragraph>
          Fiola Vault offers a secure and efficient platform for uploading and analyzing your data. This guide provides detailed instructions on how to use our platform effectively.
        </Typography>

        <Typography variant="h4" sx={{ color: '#269454', marginBottom: '20px' }}>
          Getting Started
        </Typography>
        
        <Typography variant="h4" sx={{ color: '#269454' }}>
          1. Account Creation
        </Typography>
        <Typography variant="body1" paragraph>
          To begin using Fiola Vault, navigate to our registration page and select "Create an Account". Follow the simple steps to register, which include mobile phone and email verification for security purposes.
        </Typography>

        <Typography variant="h4" sx={{ color: '#269454' }}>
          2. Initial Setup
        </Typography>
        <Typography variant="body1" paragraph>
          Once registered, you will land on the homepage which should resemble the following layout:
        </Typography>
        <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
          <Card sx={{ maxWidth: '100%' }}>
            <CardMedia
              component="img"
              image="https://developers.finexos.io/assets/images/Screenshot_22-1e6b07818f3ba9a687def918d8adcf19.png"
              alt="Homepage Overview"
            />
          </Card>
        </Box>

        <Typography variant="h4" sx={{ color: '#269454', marginBottom: '20px' }}>
          Uploading Your Data
        </Typography>

        <Typography variant="h4" sx={{ color: '#269454' }}>
          1. Selecting Data Type
        </Typography>
        <Typography variant="body1" paragraph>
          Choose the type of data you wish to upload, such as Transactional, CRA, or Loan Origination Data. You can start by selecting the appropriate option as shown below:
        </Typography>
        <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
          <Card sx={{ maxWidth: '100%' }}>
            <CardMedia
              component="img"
              image="https://developers.finexos.io/assets/images/Screenshot_1-d09c8ed01eda28871010df4a37e3d58b.png"
              alt="Data Type Selection"
            />
          </Card>
        </Box>

        <Typography variant="h4" sx={{ color: '#269454' }}>
          2. Accessing the Upload Menu
        </Typography>
        <Typography variant="body1" paragraph>
          To upload data, click on the cloud icon where the green arrow points. This opens a menu showing any previously uploaded data:
        </Typography>
        <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
          <Card sx={{ maxWidth: '50%' }}>
            <CardMedia
              component="img"
              image="https://developers.finexos.io/assets/images/Screenshot_2-f442c3d4bd7bfeb407098c554270447d.png"
              alt="Upload Menu"
            />
          </Card>
        </Box>
        <Typography variant="body1" paragraph>
          Initially, the upload area will be empty:
        </Typography>
        <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
          <Card sx={{ maxWidth: '50%' }}>
            <CardMedia
              component="img"
              image="https://developers.finexos.io/assets/images/Screenshot_3-a17870df64bb809fd2e4a7e021c853d6.png"
              alt="Empty Upload Menu"
            />
          </Card>
        </Box>
        <Typography variant="body1" paragraph>
          Remember, Fiola Vault allows multiple files to be uploaded under the same category.
        </Typography>

        <Typography variant="h4" sx={{ color: '#269454' }}>
          3. Confirming Data Privacy
        </Typography>
        <Typography variant="body1" paragraph>
          Before submitting, ensure all personally identifiable information (PII) is removed from your files. Confirm your privacy compliance as shown below and then submit:
        </Typography>
        <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
          <Card sx={{ maxWidth: '100%' }}>
            <CardMedia
              component="img"
              image="https://developers.finexos.io/assets/images/Screenshot_4-ff6e98985f97f9fc919956f591827d2a.png"
              alt="Data Privacy Confirmation"
            />
          </Card>
        </Box>

        <Typography variant="h4" sx={{ color: '#269454' }}>
          Completion and Confirmation
        </Typography>
        <Typography variant="body1" paragraph>
          After submission, our system immediately starts analyzing your data. Successful uploads will redirect you to a confirmation page:
        </Typography>
        <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
          <Card sx={{ maxWidth: '100%' }}>
            <CardMedia
              component="img"
              image="https://developers.finexos.io/assets/images/Screenshot_5-0124d2076c5da16b120cf2e067970c1b.png"
              alt="Upload Confirmation"
            />
          </Card>
        </Box>
        <Typography variant="body1" paragraph>
          For any questions or further assistance, please contact our support team. Enjoy seamless data management with Fiola Vault!
        </Typography>
      </Container>
    </ThemeProvider>
  );
};

export default VaultGuide;
