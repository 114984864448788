import React from 'react';
import { Container, Typography, Box, Card, CardMedia } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import fcsreport from '../../Assets/images/fcsreport.png';
import bills1 from '../../Assets/images/bills1.png';
import bills2 from '../../Assets/images/bills2.png';
import bills3 from '../../Assets/images/bills3.png';
import bills4 from '../../Assets/images/bills4.png';

const theme = createTheme({
  typography: {
    h1: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 500,
      fontSize: '65px',
      lineHeight: '110%',
    },
    h3: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 500,
      fontSize: '36px',
      lineHeight: '120%',
    },
    h4: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 500,
      fontSize: '25px',
      lineHeight: '140%',
    },
    h5: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '120%',
      color: '#269454',
    },
    h6: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '120%',
      color: '#269454',
    },
    body1: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '150%',
    },
    h2: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '150%',
      fontStyle: 'italic',
    },
  },
});

const FiolaApp = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container sx={{ padding: '20px', color: '#333' }}>
        <Typography variant="h3" align="center" sx={{ marginBottom: '40px', color: '#269454' }}>
          Fiola App
        </Typography>
        
        <Typography variant="h4" sx={{ color: '#269454' }}>
          Getting Started
        </Typography>
        <Typography variant="body1" paragraph>
          This is our Fiola application there are a lot of useful budgeting features to help you be in control of your finances, along with a updated FCS report giving you feedback on how you're doing.
        </Typography>
        
        <Typography variant="h4" sx={{ color: '#269454' }}>
          FCS Report
        </Typography>
        <Typography variant="body1" paragraph>
          This is our FCS Report page it will give you an overview of your current finances. It will give you complimentary feedback if you're score has improved and give you information on your cash management and sources of income.
        </Typography>
        <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
          <Card sx={{ maxWidth: '30%', margin: '0 auto' }}>
            <CardMedia
              component="img"
              image={fcsreport}
              alt="Homepage Overview"
            />
          </Card>
        </Box>
        
        <Typography variant="h4" sx={{ color: '#269454' }}>
          Bills Page
        </Typography>
        <Typography variant="body1" paragraph>
          This is your bills page, when you land on the page initially you're greeted with an overview of your finances showing your current balance, you're average monthly income and your average monthly expenditure.
        </Typography>
        <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
          <Card sx={{ maxWidth: '30%', margin: '0 auto' }}>
            <CardMedia
              component="img"
              image={bills1}
              alt="Data Type Selection"
            />
          </Card>
        </Box>
        
        <Typography variant="h4" sx={{ color: '#269454' }}>
          Expenditure Breakdown
        </Typography>
        <Typography variant="body1" paragraph>
          If you scroll down you can see all your expenses categorised for you instantly allowing you to see exactly where your money goes to give you a visual representation. You are able to tap on any category and it will show you how much in total you are spending in the category each month.
        </Typography>
        <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
          <Card sx={{ maxWidth: '30%', margin: '0 auto' }}>
            <CardMedia
              component="img"
              image={bills2}
              alt="Upload Menu"
            />
          </Card>
        </Box>
        
        <Typography variant="h4" sx={{ color: '#269454' }}>
          Essential vs Non-Essential
        </Typography>
        <Typography variant="body1" paragraph>
          You can also swipe right into your essential vs non-essential spending.
        </Typography>
        <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
          <Card sx={{ maxWidth: '30%', margin: '0 auto' }}>
            <CardMedia
              component="img"
              image={bills3}
              alt="Empty Upload Menu"
            />
          </Card>
        </Box>
        
        <Typography variant="h4" sx={{ color: '#269454' }}>
          Income vs Expenditure
        </Typography>
        <Typography variant="body1" paragraph>
          Here is your income vs expensed chart where you can see quarterly and you are able to swipe right or left to see the other months. Within this graph you're able to visualise how your expenses go up or down along with your income. In this scenario the user has two sources of income one stable and another that is an additional source where the user can take extra hours and earn extra income.
        </Typography>
        <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
          <Card sx={{ maxWidth: '30%', margin: '0 auto' }}>
            <CardMedia
              component="img"
              image={bills4}
              alt="Data Privacy Confirmation"
            />
          </Card>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default FiolaApp;
