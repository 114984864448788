import React from 'react'

export interface UserInfo {
  username: string
  email: string
  phone: string
}

interface UserInfoContextProps {
  userInfo: UserInfo | null
  setUserInfo: (data: UserInfo | null) => void
  setTrainingIteration: (iteration: number) => void
  setTrainingAllowed: (allowed: boolean) => void
  setTrainingData: (data: any[]) => void,
  setTrainingIterrationData: (data: any[]) => void,
  setScorecardData: (data: any[]) => void,
  getTrainingIteration: () => number
  getTrainingAllowed: () => boolean
  getTrainingData: () => any[]
  getTrainingIterrationData: () => any[]
  getScorecardData: () => any[]
  setModelData: (data: any[]) => void
  getModelData: () => any[]
  getLoanData: () => any[]
  setLoanData: (data: any[]) => void
  setRangeValues: (ranges: number[]) => void
  setRangeLabels: (labels: string[]) => void
  setInterestRates: (rates: number[]) => void
  getRangeValues: () => number[]
  getRangeLabels: () => string[]
  getInterestRates: () => number[]
  setThreshold: (threshold: number[]) => void;
  getThreshold: () => number[];
  setDataFetched: (fetched: boolean) => void;
  getDataFetched: () => boolean;
}

export const UserInfoContext = React.createContext<UserInfoContextProps>({
  userInfo: null,
  setUserInfo: () => { },
  setTrainingIteration: (iteration) => { },
  setTrainingAllowed: (allowed) => { },
  setTrainingData: (data)  => { },
  setScorecardData: (data)  => { },
  setTrainingIterrationData: (data)  => { },
  getTrainingData: ()  => [],
  getScorecardData: ()  => [],
  setModelData: (data)  => { },
  getModelData: ()  => [],
  getLoanData: ()  => [],
  setLoanData: (data)  => { },
  getTrainingIterrationData: ()  => [],
  getTrainingIteration: () => 0,
  getTrainingAllowed: () => true,
  setRangeValues: (ranges) => { },
  setRangeLabels: (labels) => { },
  setInterestRates: (rates) => { },
  getRangeValues: () => [0, 25, 50, 75, 100],
  getRangeLabels: () => ['low', 'low-medium', 'medium', 'medium-high', 'high'],
  getInterestRates: () => [19, 21, 29, 30, 40, 100],
  setThreshold: (threshold) => { },
  getThreshold: () => [0, 75],
  setDataFetched: () => {},
  getDataFetched: () => false,
})
