import React from 'react';
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';
import Handle from './Handle';
import Tick from './Tick';
import Track from './Track';

const RulerSlider = (
    {sliderValues, setSliderValues, removeHandle, setSliderValue, getTicks, getRangeValues, getLabel}: {
        sliderValues: number[],
        setSliderValues: (values: number[]) => void,
        removeHandle: (index: number) => void,
        setSliderValue: (index: number, value: number) => void,
        getTicks: () => number[],
        getRangeValues: () => number[],
        getLabel: (value: number) => string
    }
) => {
    
    return (
        <Slider
                vertical={true}
                reversed={true}
                rootStyle={{ position: 'relative', width: '100%', height: 400, marginTop: 15 }}
                domain={[0, 100]}
                step={0.1}
                values={sliderValues}
                mode={2}
                onSlideEnd={(values) => { setSliderValues(Array.from(values)) }}
              >
                <Rail>
                  {({ getRailProps }) => <div className="rail" {...getRailProps()} />}
                </Rail>

                <Handles>
                  {({ handles, getHandleProps }) => (
                    <div className="slider-handles">
                      {handles.map((handle, index) => (
                        <Handle
                          key={handle.id}
                          handle={handle}
                          getHandleProps={getHandleProps}
                          index={index}
                          removeHandle={removeHandle}
                          setValue={setSliderValue}
                        />
                      ))}
                    </div>
                  )}
                </Handles>
                <Tracks left={false} right={false}>
                  {({ tracks, getTrackProps }) => (
                    <div>
                      {tracks.map((track) => (
                        <Track key={track.id} track={track} getTrackProps={getTrackProps} />
                      ))}
                    </div>
                  )}
                </Tracks>
                <Ticks values={getTicks()}>
                  {({ ticks }) => (
                    <div className="slider-ticks">
                      {ticks.map((tick, index) => (
                        <Tick key={tick.id} tick={tick} rangeValues={getRangeValues()} getLabel={getLabel} />
                      ))}
                    </div>
                  )}
                </Ticks>
              </Slider>
    )
}

export default RulerSlider;