import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap'; //, Button
import styles from './weightings.module.css';
import { UserInfoContext } from '../../context/user-info';
import { routes } from '../../constants/routes';

export default function Weightings(): JSX.Element {
  const [data, setData] = useState([]);
  const [trainingRows, setTrainingRows] = useState(0);
  const {
    getTrainingIteration,
    getTrainingAllowed
  } = React.useContext(UserInfoContext);

  useEffect(() => {
    const currentIteration = getTrainingIteration();
    fetch(process.env.PUBLIC_URL + '/Assets/data/epochData.json')
      .then(response => response.json())
      .then(jsonData => {
        const mergedData = jsonData[currentIteration].features.map((feature: any[], index: string | number) => ({
          variable: feature,
          description: jsonData[currentIteration].desc?.[index] ?? null,
          weight: jsonData[currentIteration].coefs[index]
        }));
        setTrainingRows(jsonData[jsonData.length - 1].train_obs);
        setData(mergedData);
      });
  }, [getTrainingIteration]);

  const getWeightClass = (weight: number) => {
    if (weight > 0) {
      return styles.weightPositive;
    } else if (weight < 0) {
      return styles.weightNegative;
    } else {
      return '';
    }
  };

  return (
    <div className={styles.scorecardContainer}>
      <h1 className={styles.scorecardHeader}>Risk Model Weightings</h1>
      <div className={styles.scorecardInfoRow}>
        <div className={styles.scorecardColumn}>
        {
            getTrainingAllowed() &&
            <p>
              There are {trainingRows} observations available, go to Risk Configuration to start
            </p>
          }
        </div>
        <div className={styles.scorecardColumnHalf}>
          {
            getTrainingAllowed() &&
            <div>
              <a href={ routes.scorecardManagement }>
               {/* <Button className={styles.scorecardButton}>Model Training</Button> */}
              </a>
            </div>
          }
        </div>
      </div>
      <div className={styles.scorecardRow}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Description</th>
              <th>Variable</th>
              <th>Weight</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item: any, index: number) => (
              <tr key={index}>
                <td>{item?.description ?? "Description goes here"}</td>
                <td>{item?.variable}</td>
                <td className={getWeightClass(item.weight)}>{item.weight.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
