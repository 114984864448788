import { Container, Typography, Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    h1: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 500,
      fontSize: '65px',
      lineHeight: '110%',
    },
   
    h3: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 500,
      fontSize: '36px',
      lineHeight: '120%',
    },
    h4: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 500,
      fontSize: '25px',
      lineHeight: '140%',
    },
    h5: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '120%',
      color: '#269454',
    },
    h6: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '120%',
      color: '#269454',
    },
    body1: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '150%',
    },
    h2: {
        fontFamily: 'IBM Plex Sans, sans-serif',
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '150%',
        fontStyle: 'italic',
      },
  },
});

const CreditRiskConsoleOverview = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container sx={{ padding: '20px', color: '#333' }}>
        <Typography variant="h3" align="center" sx={{ marginBottom: '20px', color: '#269454' }}>
          Credit Risk Console 
        </Typography>
        
        
        <Typography variant="h2" paragraph>
          The Credit Risk Console integrates a number of tools to effectively manage, customise, and govern credit risk models utilising Finexos’s unique FCS metrics.
        </Typography>
        
        <Typography variant="h4" sx={{ margin: '10px 0', color: '#269454' }}>
          Risk Configuration Tool
        </Typography>
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
          <Box sx={{ maxWidth: '100%', textAlign: 'left' }}>
            <Typography variant="h6">
              <b></b> Selection and Customisation of Risk Parameters
            </Typography>
            <Typography variant="body1" paragraph>
                Users can define their specific risk appetite, adjust interest rates, and instantly see the real-time impact predictions of these changes to the loan book. This advanced customisation facilitates accurate, risk assessments that align with the strategic goals of the organisation, enhancing the precision and effectiveness of risk management practices.
            </Typography>
            
            <Typography variant="h4" sx={{ margin: '20px 0', color: '#269454' }}>
              Current Credit Risk Model Weightings
            </Typography>
            <Typography variant="h5">
               Real-time display of current model weightings
            </Typography>
            <Typography variant="body1" paragraph>
               Gain immediate insights into the distribution and influence of different models on overall credit risk evaluations. This real-time visibility helps stakeholders understand the dynamics and implications of model weightings on risk assessments.
            </Typography>
            
            <Typography variant="h4" sx={{ margin: '10px 0', color: '#269454' }}>
              Retraining Console
            </Typography>
            <Typography variant="h5">
              <b></b> Dynamic model retraining with updated data
            </Typography>
            <Typography variant="body1" paragraph>
               Enables the continuous improvement of risk models by allowing users to retrain them with the latest data inputs. This dynamic retraining process ensures models remain accurate and relevant, with the console offering tools to initiate, monitor, and deploy updated models seamlessly, thereby maintaining high standards of predictive performance.
            </Typography>

            <Typography variant="h4" sx={{ margin: '10px 0', color: '#269454' }}>
              Governance Console
            </Typography>
            <Typography variant="h5" sx={{ color: '#269454' }}>
              <b></b> Model version tracking
            </Typography>
            <Typography variant="body1" paragraph>
               Provides an audit history of all credit risk model versions, ensuring transparency, compliance, and auditability. This comprehensive tracking is crucial for maintaining strict governance standards and adhering to regulatory requirements, supporting a robust and trustworthy risk management framework.
            </Typography>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default CreditRiskConsoleOverview;
