import { Outlet } from 'react-router-dom';
import styles from './BasicLayout.module.css';
import SideMenu from '../components/Menu';
import PageHeader from '../components/PageHeader';

export default function BasicLayout(): JSX.Element {
  return (
    <div className={styles.layoutContainer}>
      <div className="headerAndSidebarWrapper">
      <div className={styles.navbarWrapper}>
      <PageHeader />
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.sidebarWrapper}>
          <SideMenu />
        </div>
        <div className={styles.mainContent}>
          <Outlet />
        </div>
      </div>
      </div>
    </div>
  );
}