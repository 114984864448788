/* eslint-disable */
import React, { useState, useEffect, FormEvent } from 'react';
import { Table, Button, ProgressBar, Tabs, Tab } from 'react-bootstrap';

import styles from './index.module.css';
import { UserInfoContext } from '../../context/user-info';

export default function Scorecard(): JSX.Element {
  const [initialData, setInitialData] = useState([]);
  const [currentIterration, setCurrentIterration] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isTraining, setIsTraining] = useState(false);
  const [isTrainingDone, setIsTrainingDone] = useState(false);
  const [trainingRows, setTrainingRows] = useState(0);
  const [initialMthwDiff, setInitialMthwDiff] = useState(0.00);
  const [initialGini, setInitialGini] = useState(0.00);
  const [sliderValues, setSliderValues] = useState([0, 75]);
  const {
    getTrainingAllowed,
    getTrainingIteration,
    setTrainingIteration,
    setTrainingAllowed,
    getTrainingData,
    getTrainingIterrationData,
    getThreshold,
    
    getModelData,
    setModelData
  } = React.useContext(UserInfoContext);

  useEffect(() => {
    setSliderValues(getThreshold());
    setInitialMthwDiff(getTrainingIterrationData()?.[getTrainingIteration()]?.mthw_diff);
    setInitialGini(getTrainingIterrationData()?.[getTrainingIteration()]?.gini);
    setTrainingRows(getTrainingIterrationData()?.[getTrainingIterrationData()?.length - 1]?.train_obs);
    setInitialData(getTrainingData()?.[getTrainingIteration()]);
  }, [getTrainingIteration, getTrainingIterrationData, getTrainingData, getThreshold]);

  useEffect(() => {
    if (isTraining && progress < 100) {
      const timer = setTimeout(() => {
        if (progress >= ((currentIterration + 1) * 100 / 7)) {
          setCurrentIterration(currentIterration + 1);
        }
        setProgress(progress + 5); // increment progress by 5% every second
      }, 1000);
      return () => clearTimeout(timer); // cleanup on unmount
    }
    if (progress >= 100) {
      setIsTraining(false);
      setIsTrainingDone(true);
    }
  }, [isTraining, progress, currentIterration]);


  const handleTrainClick = () => {
    setProgress(0); // reset progress
    setIsTraining(true); // start training
  };

  const handleRevertClick = () => {
    setProgress(0); // reset progress
    setIsTraining(false); // start training
    setCurrentIterration(0);
    setIsTrainingDone(false);
  };

  const handleConfirmClick = () => {
    setInitialMthwDiff(getTrainingIterrationData()?.[currentIterration]?.mthw_diff);
    setInitialGini(getTrainingIterrationData()?.[currentIterration]?.gini);
    setInitialData(getTrainingData()?.[currentIterration]);
    setProgress(0);
    setIsTraining(false);
    setTrainingIteration(currentIterration);
    setTrainingAllowed(false);
    const models = getModelData() ?? [];
    models.unshift(
      {
        date: Date.now(),
        model_version: models?.[0]?.model_version?.toString()?.replace(/0(?=[^0]*$)/, '5') ??  "1.0.5",
        gini: getTrainingIterrationData()?.[currentIterration]?.gini,
        type: "retrained",
        id: models?.[0]?.id
      }
    );
    setModelData(models);
  };

  

  const getWeightClass = (weight: number) => {
    if (weight > 0) {
      return styles.weightPositive;
    } else if (weight < 0) {
      return styles.weightNegative;
    } else {
      return '';
    }
  };

  return (
    <div className={styles.scorecardManagementContainer}>
      <h1 className={styles.scorecardManagementHeader}>Retraining Console</h1>
      <Tabs defaultActiveKey="first" id="uncontrolled-tab-example">
        <Tab eventKey="first" title="Retraining Console">
          <div className={styles.scorecardManagementInfoRow}>
            <div className={styles.scorecardManagementColumn}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th></th>
                    <th>Mathews Diff</th>
                    <th>Gini</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>{progress < 1 ? "current" : "previous"}</th>
                    <td className={getWeightClass(initialMthwDiff)}>{initialMthwDiff?.toFixed(2)}</td>
                    <td className={getWeightClass(initialGini)}>{initialGini?.toFixed(2)}</td>
                  </tr>
                  {
                    progress > 0 &&
                    <tr>
                      <th>current</th>
                      <td className={getWeightClass(getTrainingIterrationData()?.[currentIterration]?.mthw_diff)}>{getTrainingIterrationData()?.[currentIterration]?.mthw_diff.toFixed(2)}</td>
                      <td className={getWeightClass(getTrainingIterrationData()?.[currentIterration]?.gini)}>{getTrainingIterrationData()?.[currentIterration]?.gini.toFixed(2)}</td>
                    </tr>
                  }
                </tbody>
              </Table>
            </div>
            <div className={styles.scorecardManagementColumnHalf}>
              {
                !isTraining && !isTrainingDone && getTrainingAllowed() &&
                <p>
                  There are {trainingRows} observations available for training.
                </p>
              }
              {
                !isTraining && !isTrainingDone && getTrainingAllowed() &&
                <Button className={styles.scorecardManagementButton} onClick={handleTrainClick}>Train</Button>
              }
              {
                (isTraining || isTrainingDone) && getTrainingAllowed() &&
                <div className={styles.scorecardManagementTrainingInfo}>
                  <Table striped bordered hover>
                    <tbody>
                      <tr>
                        <td>Epoch: {getTrainingIterrationData()?.[currentIterration]?.epoch}</td>
                        <td>Observations: {getTrainingIterrationData()?.[currentIterration]?.train_obs}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              }
              {
                isTrainingDone && getTrainingAllowed() &&
                <div>
                  <Button className={styles.scorecardManagementButton} onClick={handleRevertClick}>Revert</Button>
                  <Button className={styles.scorecardManagementButton} onClick={handleConfirmClick}>Confirm</Button>
                </div>
              }
              {
                progress > 0 && !isTrainingDone && getTrainingAllowed() &&
                <ProgressBar className={styles.scorecardManagementProgressBar} now={progress} label={`${progress}%`} variant={isTraining ? "info" : "success"} />
              }
              {!getTrainingAllowed() && <p>Please provide more data through the API for further training</p>}
            </div>
          </div>
          <div className={styles.scorecardManagementRow}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Variable</th>
                  <th>{progress < 1 ? "weight" : "previous weigth"}</th>
                  {
                    progress > 0 &&
                    <th>weight</th>
                  }
                </tr>
              </thead>
              <tbody>
                {initialData?.map((item: any, index: number) => (
                  <tr key={index}>
                    <td>{item?.description ?? "Description goes here"}</td>
                    <td>{item?.variable}</td>
                    <td className={getWeightClass(item.weight)}>{item.weight.toFixed(2)}</td>
                    {
                      progress > 0 &&
                      <td className={getWeightClass(getTrainingData()?.[currentIterration]?.[index]?.weight)}>{getTrainingData()?.[currentIterration]?.[index]?.weight.toFixed(2)}</td>
                    }
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Tab>
        
      </Tabs>
    </div>
  );
}