import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemText } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    typography: {
      h1: {
        fontFamily: 'IBM Plex Sans, sans-serif',
        fontWeight: 500,
        fontSize: '65px',
        lineHeight: '110%',
      },
      h2: {
        fontFamily: 'IBM Plex Sans, sans-serif',
        fontWeight: 500,
        fontSize: '50px',
        lineHeight: '110%',
      },
      h3: {
        fontFamily: 'IBM Plex Sans, sans-serif',
        fontWeight: 500,
        fontSize: '36px',
        lineHeight: '120%',
      },
      h4: {
        fontFamily: 'IBM Plex Sans, sans-serif',
        fontWeight: 500,
        fontSize: '25px',
        lineHeight: '140%',
      },
      h5: {
        fontFamily: 'IBM Plex Sans, sans-serif',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '120%',
        color: '#269454',
      },
      h6: {
        fontFamily: 'IBM Plex Sans, sans-serif',
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '120%',
        color: '#269454',
      },
      body1: {
        fontFamily: 'IBM Plex Sans, sans-serif',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '150%',
      },
    },
  });
  

const FiolaOverview = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container sx={{ padding: '20px', color: '#333' }}>
        <Typography variant="h3" align="center" sx={{ marginBottom: '40px', color: '#269454' }}>
          Fiola Overview
        </Typography>
        
        <Typography variant="h4" sx={{ color: '#269454' }}>
          Avalanche
        </Typography>
        <Typography paragraph>
          Fiola's Avalanche algorithm empowers users to take control of their credit card debt and achieve financial freedom. By intelligently analyzing your outstanding balances, this innovative tool guides you towards the most efficient debt repayment strategy, saving you money on interest payments in the process.
        </Typography>
        
        <Typography variant="h4" align="center" sx={{ margin: '20px 0', color: '#269454' }}>
          How It Works
        </Typography>
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '40px' }}>
          <Box sx={{ maxWidth: '50%', textAlign: 'left' }}>
            <Typography variant="h4" sx={{ color: '#269454' }}>
              Step 1: Secure Connection
            </Typography>
            <List sx={{ listStyleType: 'disc', pl: 2 }}>
              <ListItem sx={{ display: 'list-item' }}>
                <ListItemText primary="Connect your credit cards seamlessly through Fiola's secure Open Banking APIs." />
              </ListItem>
            </List>
            
            <Typography variant="h4" sx={{ color: '#269454' }}>
              Step 2: Intelligent Analysis
            </Typography>
            <List sx={{ listStyleType: 'disc', pl: 2 }}>
              <ListItem sx={{ display: 'list-item' }}>
                <ListItemText primary="Advanced algorithm analyses your current credit card debt, identifying interest rates and balances." />
              </ListItem>
            </List>
            
            <Typography variant="h4" sx={{ color: '#269454' }}>
              Step 3: Optimized Repayment Plan
            </Typography>
            <List sx={{ listStyleType: 'disc', pl: 2 }}>
              <ListItem sx={{ display: 'list-item' }}>
                <ListItemText primary="Based on your unique situation, Fiola generates a personalized debt repayment plan, prioritizing high-interest balances to minimize the total interest paid." />
              </ListItem>
            </List>
            
            <Typography variant="h4" sx={{ color: '#269454' }}>
              Step 4: Track Your Progress
            </Typography>
            <List sx={{ listStyleType: 'disc', pl: 2 }}>
              <ListItem sx={{ display: 'list-item' }}>
                <ListItemText primary="Monitor your debt reduction journey with Fiola's intuitive dashboard, celebrating milestones and staying motivated along the way." />
              </ListItem>
            </List>
          </Box>
          
          <Box sx={{ maxWidth: '45%', textAlign: 'center' }}>
            <Typography variant="h4" sx={{ color: '#269454' }}>
              Key Benefits
            </Typography>
            <List sx={{ listStyleType: 'disc', pl: 2 }}>
              <ListItem sx={{ display: 'list-item' }}>
                <ListItemText primary="Reduce Interest Payments: Save money by tackling high-interest debt first." />
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <ListItemText primary="Personalized Plan: Get a tailored strategy that fits your financial situation." />
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <ListItemText primary="Easy Tracking: Stay motivated with clear progress updates." />
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <ListItemText primary="Secure & Convenient: Connect your accounts safely and manage your debt in one place." />
              </ListItem>
            </List>
          </Box>
        </Box>
        
        <Typography variant="h4" sx={{ color: '#269454', marginTop: '40px' }}>
          Fiola App
        </Typography>
        <Typography paragraph>
          Take charge of your financial well-being with the Fiola app. This comprehensive tool empowers you to understand your finances, build smarter spending habits, and achieve your financial goals. By connecting securely to your bank accounts through Open Banking, Fiola provides a detailed overview of your current financial status and offers personalized guidance to improve it.
        </Typography>
        
        <Typography variant="h4" sx={{ color: '#269454', marginTop: '20px' }}>
          Key Features
        </Typography>
        <List sx={{ listStyleType: 'disc', pl: 2 }}>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary="Intelligent Budgeting: Categorize your spending, identify areas for savings, and create realistic budgets that work for you." />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary="Essential vs. Non-Essential Analysis: Easily distinguish between necessary expenses and discretionary spending, helping you make informed choices." />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary="FCS Score Tracking & Improvement: Monitor your FCS score, understand the factors that influence it, and receive actionable tips to improve it." />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary="Savings Goals: Set clear savings targets, track your progress, and receive encouragement along the way." />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary="Financial Health Check: Gain insights into your spending patterns, income, and overall financial health, all in one place." />
          </ListItem>
        </List>
        
        <Typography variant="h4" sx={{ color: '#269454', marginTop: '20px' }}>
          Benefits
        </Typography>
        <List sx={{ listStyleType: 'disc', pl: 2 }}>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary="Clarity and Control: Understand where your money is going and take charge of your finances." />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary="Improved Financial Habits: Develop smarter spending and saving behaviours." />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary="Higher Credit Score: Boost your creditworthiness and unlock better financial opportunities." />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary="Achieve Your Goals: Make progress towards your financial aspirations with personalized guidance." />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary="Financial Peace of Mind: Gain confidence and reduce financial stress." />
          </ListItem>
        </List>
      </Container>
    </ThemeProvider>
  );
};

export default FiolaOverview;
