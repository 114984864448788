import React from 'react';
import { UserInfoContext } from '../../context/user-info';
import styles from './index.module.css';

export default function ModelGovernance(): JSX.Element {
  const {
    getModelData
  } = React.useContext(UserInfoContext);

  const getWeightClass = (weight: number) => {
    if (weight > 0) {
      return styles.weightPositive;
    } else if (weight < 0) {
      return styles.weightNegative;
    } else {
      return '';
    }
  };

  const renderModel = (model: any) => {
    const date = new Date(model?.date);
    return (
      <div className={styles.modelGovernanceBox}>
        <div className={styles.modelGovernanceText}>Model Id: {model?.id}</div>
        <div className={styles.modelGovernanceText}>Type: {model?.type}</div>
        <div className={styles.modelGovernanceText}>Date: {
          date?.toLocaleDateString("en-US", {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })}</div>
        <div className={styles.modelGovernanceText}>Model Version: {model?.model_version}</div>
        <div className={styles.modelGovernanceText}>Gini Score: <span className={getWeightClass(model?.gini?.toFixed(2))}>{model?.gini?.toFixed(2)}</span></div>
      </div>
    );
  }

  const getActiveModel = () => {
    const activeModel = getModelData()?.[0] ?? {};
    return renderModel(activeModel);
  }

  const getHistoryModels = () => {
    const models = getModelData() ?? [];
    const elements = [];

    for (let i = 1, count = 1; i < models.length; i++, count++) {
      if (models?.[i]) {
        // Before every 3rd model (considering the empty divs in the count), insert an empty div
        if (count % 3 === 0) {
          elements.push(<div key={`empty-${i}`} className={styles.emptyDiv}></div>);
          count++; // Increment count to account for the added empty div
        }
        elements.push(renderModel(models[i]));
      }
    }

    return <>{elements}</>;
  }

  return (
    <div className={styles.modelGovernanceContainer}>
      <h1 className={styles.modelGovernanceHeader}>Governance Console</h1>

      <div className={styles.modelGovernanceRow}>
        <div className={styles.modelGovernanceTitleBox}>
          <h3 className={styles.modelGovernanceTitleText}>Active:</h3>
        </div>
        {getActiveModel()}
      </div>

      <div className={styles.modelGovernanceRow}>
        <div className={styles.modelGovernanceTitleBox}>
          <h3 className={styles.modelGovernanceTitleText}>History:</h3>
        </div>
        {getHistoryModels()}
      </div>
    </div>
  );
}
