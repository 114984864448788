import React, { useState, useEffect } from 'react';


const Handle = ({ handle: { id, value, percent }, getHandleProps, index, removeHandle, setValue }: { handle: { id: string, value: number, percent: number }, getHandleProps: any, index: number, removeHandle: any, setValue: any }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editValue, setEditValue] = useState<number>(value);
    const top = index === 0 ? '-2' : '-6';
    const pencilBottom = index === 0 ? '3' : '-3';
    const circlePosition = index === 0 ? '90' : '10';
    const digits = value === 0.0 || value === 100.0 ? 0 : 1;
    
    useEffect(() => {
        setEditValue(value); // Update editValue whenever the external value changes
    }, [value]);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseFloat(e.target.value);
        setEditValue(newValue);
        // Optionally, update the value in real-time
        // setValue(index, newValue);
    };

    const commitValue = () => {
        if (!isNaN(editValue)) {
            setValue(index, editValue);
        }
        setIsEditing(false);
    };

    const handleBlur = () => {
        commitValue();
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            commitValue();
        } else if (e.key === 'Escape') {
            setEditValue(value); // Revert to original value
            setIsEditing(false);
        }
    };
  
    return (
      <div
        style={{
          bottom: `calc(${100 - percent}%)`,
          position: 'absolute',
          zIndex: 2,
          textAlign: 'center',
          cursor: 'pointer',
          color: '#333',
          width: '50px'
        }}
        {...getHandleProps(id)}
      >
        <div
          style={{
            position: 'absolute',
            left: '-10%',
            width: '6px', // Width of the T's top part
            height: '10px', // Height of the T's top part
            backgroundColor: 'black',
            top: `${top}px`, // Adjust as needed to align with the center stem
          }}
        ></div>
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '6px',
            top: '-2px',
            backgroundColor: 'black',
          }}
        ></div>
        <div
          style={{
            position: 'absolute',
            right: '-10%', // Adjust based on the width of the T's top part
            width: '6px', // Width of the T's top part
            height: '10px', // Height of the T's top part
            backgroundColor: 'black',
            top: `${top}px`, // Adjust as needed to align with the center stem
          }}
        ></div>
        <div
          style={{
            position: 'absolute',
            width: '30px', // Circle size
            height: '20px', // Circle size
            backgroundColor: 'black',
            color: 'white', // Text color
            display: 'flex',
            justifyContent: 'center', // Center text horizontally
            alignItems: 'center', // Center text vertically
            left: '50%', // Center the circle relative to its parent
            top: '50%', // Center the circle relative to its parent
            transform: `translate(-50%, -${circlePosition}%)`, // Adjust position to truly center
            fontSize: '12px', // Adjust text size as needed
          }}
        >
          {isEditing ? (
            <input
              type="number"
              value={editValue}
              onChange={handleValueChange}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              min={0}
              max={100}
              step={0.1}
              autoFocus
              style={{
                position: 'relative',
                width: '40px',
                textAlign: 'center',
                color: 'white', // Text color set to white for contrast
                backgroundColor: 'black', // Input background color set to black
                border: '1px solid #A9A9A9', // Optional: dark grey border for better visibility
                borderRadius: '4px',
              }}
            />
          ) : (
            <>
              <span>{value.toFixed(digits)}</span>
              <button
                onClick={handleEditClick}
                style={{
                  position: 'absolute',
                  left: '37px',
                  bottom: `${pencilBottom}px`,
                  cursor: 'pointer',
                  backgroundColor: 'transparent',
                  border: 'none',
                }}>✏️</button>
            </>
          )}
        </div>
      </div>
    );
  }

  export default Handle;