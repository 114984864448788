import { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import AuthLayout from './layouts/AuthLayout';
import BasicLayout from './layouts/BasicLayout';
import Login from './pages/Auth/Login';
import Resources from './pages/Resources';
import Container from 'react-bootstrap/Container';
import { routes } from './constants/routes';
import { UserInfoContext, UserInfo } from './context/user-info';
import {
  hasToken,
  getUserInfo,
  setUserInfo as saveUserInfo,
} from './utilities/storage';
import './App.css';
import ScoreCard from './pages/Scorecard';
import ScorecardManagement from './pages/ScorecardManagement';
import ModelGovernance from './pages/ModelGovernance';
import Applicants from './pages/Applicants';
import HomePage from './pages/Home';
import UnderConstruction from './pages/UnderConstruction';
import RetroOverview from './pages/RetrospectiveAnalysis/overview';
import VaultGuide from './pages/RetrospectiveAnalysis/vaultguide';
import LbvaExamples from './pages/RetrospectiveAnalysis/examples';
import CreditworthinessOverview from './pages/creditworthiness/overview';
import Avalanche from './pages/FiolaApp/avalanche';
import FiolaOverview from './pages/FiolaApp/overview';
import FiolaApp from './pages/FiolaApp/app';
import CreditRiskConsoleOverview from './pages/Scorecard/creditoverview';
import Weightings from './pages/Scorecard/weightings';
const RequireAuth = ({ children }: { children: JSX.Element }): JSX.Element => {
  const auth = hasToken();
  if (!auth) {
    return <Navigate to={routes.login} replace />;
  }
  return children;
};

const initialUserInfo = getUserInfo();

export default function App(): JSX.Element {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(initialUserInfo);
  const [trainingAllowed, setTrainingAllowed] = useState(true);
  const [trainingIteration, setTrainingIteration] = useState(0);
  const [rangeValues, setRangeValues] = useState([0, 25, 50, 75, 100]);
  const [rangeLabels, setRangeLabels] = useState(['low', 'low-medium', 'medium', 'medium-high', 'high']);
  const [interestRates, setInterestRates] = useState([19, 21, 29, 30, 40, 100]);
  const [trainingData, setTrainingData] = useState<any[]>([]);
  const [loanData, setLoanData] = useState<any[]>([]);
  const [scorecardData, setScorecardData] = useState<any[]>([]);
  const [trainingIterrationData, setTrainingIterrationData] = useState<any[]>([]);
  const [modelData, setModelData] = useState<any[]>([]);
  const [treshold, setThreshold] = useState([0, 75]);
  const [dataFetched, setDataFetched] = useState(false);
  const location = useLocation();

  const noBackgroundPaths = ['/vault-guide', '/auth/login'];
  const rootClass = noBackgroundPaths.includes(location.pathname) ? '' : 'root-background';

  const getThreshold = () => {
    return treshold;
  };

  const getLoanData = () => {
    return loanData;
  };

  const getDataFetched = () => {
    return dataFetched;
  };

  const getTrainingData = () => {
    return trainingData;
  };

  const getModelData = () => {
    return modelData;
  };

  const getTrainingIterrationData = () => {
    return trainingIterrationData;
  };

  const getScorecardData = () => {
    return scorecardData;
  };

  const getTrainingIteration = () => {
    return trainingIteration;
  };

  const getTrainingAllowed = () => {
    return trainingAllowed;
  };

  const getRangeValues = () => {
    return rangeValues;
  };

  const getRangeLabels = () => {
    return rangeLabels;
  };

  const getInterestRates = () => {
    return interestRates;
  };

  useEffect(() => {
    saveUserInfo(userInfo);
  }, [userInfo]);

  useEffect(() => {
    if (!dataFetched) {
      fetch(process.env.PUBLIC_URL + '/Assets/data/epochData.json')
        .then((response) => response.json())
        .then((jsonData) => {
          const mergedTrainingData: any = [];
          const mergedTrainingIterrationData: any = [];
          jsonData.forEach((data: any) => {
            mergedTrainingData.push(
              data.features.map((feature: any[], index: string | number) => ({
                variable: feature,
                description: data.desc?.[index] ?? null,
                weight: data.coefs[index],
              }))
            );
            mergedTrainingIterrationData.push({
              epoch: data.epoch,
              train_obs: data.train_obs,
              mthw_diff: data.mthw_diff,
              gini: data.gini,
            });
          });
          setTrainingData(mergedTrainingData);
          setTrainingIterrationData(mergedTrainingIterrationData);
          setScorecardData(mergedTrainingData[0]);
        });

      fetch(process.env.PUBLIC_URL + '/Assets/data/modelData.json')
        .then((response) => response.json())
        .then((jsonData) => {
          setModelData(jsonData);
        });

        const calculateBand = (score: number) => {
          const pd = parseFloat(score.toFixed(2)) * 100;
          for (let i = 0; i < rangeValues.length; i++) {
            if (pd <= rangeValues[i]) {
              return rangeLabels[i];
            }
          }
          return rangeLabels[0];
        }

        const calculateDecision = (score: number) => {
          const pd = parseFloat(score.toFixed(2)) * 100;
          treshold.sort((a, b) => a - b);
          if (pd >= treshold[0] && pd <= treshold[1]) {
            return 'Approved';
          }
          return 'Declined';          
        }

        fetch(process.env.PUBLIC_URL + '/Assets/data/loanApplications.json')
        .then((response) => response.json())
        .then((jsonData) => {
          const updatedLoanData = jsonData.map((row: any) => {
            return {
              ...row,
              band: calculateBand(row?.["fiolaPD"]),
              decision: calculateDecision(row?.["fiolaPD"]),
            };
          });
          setLoanData(updatedLoanData);
        });
      setTrainingAllowed(true);
      setTrainingIteration(0);
      setDataFetched(true);
    }
  }, [dataFetched, rangeValues, rangeLabels, treshold, setDataFetched, setTrainingAllowed, setTrainingIteration]);

  return (
    <div id="root" className={rootClass}>
      <UserInfoContext.Provider
        value={{
          userInfo,
          setUserInfo,
          setTrainingIteration,
          setTrainingAllowed,
          setScorecardData,
          setTrainingData,
          getTrainingData,
          getScorecardData,
          setTrainingIterrationData,
          getTrainingIteration,
          getTrainingAllowed,
          getTrainingIterrationData,
          setRangeValues,
          setRangeLabels,
          setInterestRates,
          getRangeValues,
          getRangeLabels,
          getInterestRates,
          setModelData,
          getModelData,
          setThreshold,
          getThreshold,
          setDataFetched,
          getDataFetched,
          getLoanData,
          setLoanData
        }}
      >
        <Container fluid>
          <Routes>
            <Route path="/auth" element={<AuthLayout />}>
              <Route path={routes.login} element={<Login />} />
            </Route>

            <Route path="/" element={<RequireAuth><HomePage /></RequireAuth>} />
            <Route
              path="/"
              element={
                <RequireAuth>
                  <BasicLayout />
                </RequireAuth>
              }
            > 
              <Route path={routes.weightings} element={<Weightings />} />
              <Route path={routes.creditrisk} element={<CreditRiskConsoleOverview />} />
              <Route path={routes.fiolapp} element={<FiolaApp />} />
              <Route path={routes.fiolaoverview} element={<FiolaOverview />} />
              <Route path={routes.avalnce} element={<Avalanche />} />
              <Route path={routes.creditoverview} element={<CreditworthinessOverview />} />
              <Route path={routes.lbvaexample} element={<LbvaExamples />} />
              <Route path={routes.vaultguide} element={<VaultGuide />} />
              <Route path={routes.retrooverview} element={<RetroOverview />} />
              <Route path={routes.underconstruction} element={<UnderConstruction />} />
              <Route path={routes.scorecard} element={<ScoreCard />} />
              <Route path={routes.scorecardManagement} element={<ScorecardManagement />} />
              <Route path={routes.applicants} element={<Applicants />} />
              <Route path={routes.modelGovernance} element={<ModelGovernance />} />
              <Route path={routes.resources} element={<Resources />} />
            </Route>
            <Route path="/homepage" element={<RequireAuth><HomePage /></RequireAuth>} />
          </Routes>
        </Container>
      </UserInfoContext.Provider>
    </div>
  );
}
