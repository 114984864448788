export const routes = {
  home: '/',
  login: '/auth/login',
  scorecard: '/scorecard',
  scorecardManagement: '/scorecard-management',
  applicants: '/applicants',
  modelGovernance: '/model-governance',
  resources: '/resources',
  homepage: '/homepage',
  underconstruction: '/comingsoon',
  retrooverview: '/retro-overview',
  vaultguide: '/vault-guide',
  lbvaexample: '/lbva-examples',
  creditoverview: '/credit-overview',
  avalnce: '/avalanche',
  fiolaoverview: '/fiola-overview',
  fiolapp: 'fiola-app',
  creditrisk: '/creditrisk-overview',
  weightings: '/weightings'
}
