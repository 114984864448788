import { useState, useContext } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';

import { useUserInfo } from '../../hooks/user-info';
import { UserInfoContext } from '../../context/user-info';
import { removeToken, removeUserInfo } from '../../utilities/storage';
import { MdOutlineExitToApp } from "react-icons/md";
import { CiHome } from "react-icons/ci";

import styles from './index.module.css';
import { routes } from '../../constants/routes';

export default function PageHeader(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation(); // Use useLocation to get the current path
  const { setUserInfo } = useContext(UserInfoContext);
  const userInfo = useUserInfo();
  const username = userInfo?.username;

  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleLogout = (): void => {
    setUserInfo(null);
    removeToken();
    removeUserInfo();

    navigate(routes.login);
  };

  const togglePopup = (): void => {
    setIsPopupVisible(!isPopupVisible);
  };

  return (
    <header className={styles.header}>
      <div className={styles.leftContent}>
        {/* {username && 
          <Hamburger onToggle={toggled => { collapseSidebar(!toggled); }} />
        } */}
      </div>
      <div className={styles.logo_container}>
        <div className={styles.logo} />
      </div>
      <div className={styles.rightContent}>
        {username && (
          <div className={styles.info}>
           {(location.pathname !== '/homepage' && location.pathname !== '/auth/login' && location.pathname !== '/') && (
  
  <span>
    <Link to="/homepage">
      <CiHome className={styles.homeIcon} />
    </Link>
  </span>
)}
            <img 
              src="/Assets/images/account.svg" 
              width={30} 
              height={30} 
              alt="User Icon" 
              className={styles.icon} 
              onClick={togglePopup} 
            />
            {isPopupVisible && (
              <div className={styles.popup}>
                <span className={styles.username}>{username}</span>
                <button onClick={handleLogout} className={styles.logoutButton}>
                  <MdOutlineExitToApp className={styles.logoutIcon} /> Logout
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </header>
  );
}
